import React, { lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { retry } from "./utils/CommonFunctions";
const Home = lazy(() => retry(() => import("./pages/Home")));
const Contact = lazy(() => retry(() => import("./pages/Contact")));
const About = lazy(() => retry(() => import("./pages/About")));
const Project = lazy(() => retry(() => import("./pages/Project")));
const ProjectDetails = lazy(() => retry(() => import("./pages/ProjectDetails")));
const FourOhFour = lazy(() => retry(() => import("./pages/FourOhFour")));

const Routes = () => {
  const routes = useRoutes([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/about-us",
      element: <About />,
    },
    {
      path: "/project",
      element: <Project />,
    },
    {
      path: "/project-details/:slug",
      component: ProjectDetails,
      element: <ProjectDetails />,
    },
    {
      path: "/contact",
      element: <Contact />,
    },
    {
      path: "/*",
      element: <Navigate replace to="/404" />,
    },
    {
      path: "/404",
      element: <FourOhFour />,
    },
  ]);
  return routes;
};

export default Routes;

import React from "react";
import "./../styles/Home.css";
import { trefoil } from "ldrs";
trefoil.register();

const Loading = () => {
  return (
    <>
      {/* <div className="d-flex justify-content-center align-items-center vh-100">
        <l-trefoil
          size="50"
          stroke="5"
          stroke-length="0.20"
          bg-opacity="0.1"
          speed="2"
          color="#FFFFFF"
          background="gray"
        ></l-trefoil>
      </div> */}
      <div id="loader">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="320"
          height="31"
          viewBox="0 0 320 31"
          fill="none"
        >
          <path
            d="M6.32 30H7.32V29V21.92H15.12C18.2874 21.92 21.0571 21.0045 23.0466 19.2009C25.0486 17.3861 26.16 14.7624 26.16 11.56C26.16 8.33923 25.0495 5.71411 23.0455 3.90308C21.0546 2.10396 18.2845 1.20001 15.12 1.20001H2H1V2.20001V29V30H2H6.32ZM7.32 16.04V7.08001H14.72C16.6482 7.08001 17.8884 7.44905 18.6457 8.09558C19.368 8.71218 19.84 9.74766 19.84 11.56C19.84 13.3724 19.368 14.4078 18.6457 15.0244C17.8884 15.671 16.6482 16.04 14.72 16.04H7.32Z"
            stroke="white"
            strokeWidth="2"
          />
          <path
            d="M34.0622 30H35.0622V29V19.6H38.9822C40.592 19.6 41.5447 19.8144 42.2095 20.1808C42.8569 20.5376 43.3532 21.1055 43.9119 22.0926L43.9119 22.0926L43.9165 22.1007L48.1966 29.5007L48.4854 30H49.0622H54.0222H55.7542L54.8882 28.5L50.4104 20.7439C50.4101 20.7432 50.4097 20.7426 50.4094 20.742C49.9144 19.8759 49.3754 19.0527 48.6453 18.3697C48.5442 18.2752 48.4402 18.1839 48.3328 18.096C49.8169 17.6967 51.0896 17.0294 52.0947 16.0909C53.6883 14.6028 54.5022 12.5316 54.5022 10.08C54.5022 7.30412 53.4448 5.03319 51.4827 3.48078C49.5509 1.95245 46.8453 1.20001 43.6622 1.20001H29.7422H28.7422V2.20001V29V30H29.7422H34.0622ZM43.6222 14.28H35.0622V7.08001H43.6222C45.3345 7.08001 46.4023 7.40053 47.0371 7.92244C47.6283 8.40855 48.0222 9.22128 48.0222 10.68C48.0222 12.1859 47.6245 12.9911 47.0422 13.4634C46.4112 13.9752 45.3463 14.28 43.6222 14.28Z"
            stroke="white"
            strokeWidth="2"
          />
          <path
            d="M61.4172 30H62.0682L62.3316 29.4047L65.1482 23.04H78.0062L80.8227 29.4047L81.0862 30H81.7372H86.6172H88.1585L87.5304 28.5925L75.5704 1.79248L75.306 1.20001H74.6572H68.5772H67.9284L67.664 1.79248L55.704 28.5925L55.0759 30H56.6172H61.4172ZM70.7704 10.4074L70.7766 10.3937L70.7823 10.3798L71.5772 8.44344L72.3721 10.3798L72.3778 10.3937L72.3839 10.4074L75.3961 17.16H67.7582L70.7704 10.4074Z"
            stroke="white"
            strokeWidth="2"
          />
          <path
            d="M89.8572 29V30H90.8572H103.057C107.424 30 111.085 28.6528 113.659 26.1174C116.234 23.5802 117.617 19.9548 117.617 15.6C117.617 11.2452 116.234 7.61979 113.659 5.08262C111.085 2.54726 107.424 1.20001 103.057 1.20001H90.8572H89.8572V2.20001V29ZM96.1372 24.12V7.08001H103.057C106.043 7.08001 107.964 7.7195 109.165 8.95642C110.373 10.2013 111.057 12.2629 111.057 15.6C111.057 18.9371 110.373 20.9987 109.165 22.2436C107.964 23.4805 106.043 24.12 103.057 24.12H96.1372Z"
            stroke="white"
            strokeWidth="2"
          />
          <path
            d="M126.156 30H127.156V29V2.20001V1.20001H126.156H121.836H120.836V2.20001V29V30H121.836H126.156Z"
            stroke="white"
            strokeWidth="2"
          />

          <path
            d="M136.476 30H137.476V29V21.92H145.276C148.444 21.92 151.213 21.0045 153.203 19.2009C155.205 17.3861 156.316 14.7624 156.316 11.56C156.316 8.33923 155.206 5.71411 153.202 3.90308C151.211 2.10396 148.441 1.20001 145.276 1.20001H132.156H131.156V2.20001V29V30H132.156H136.476ZM137.476 16.04V7.08001H144.876C146.804 7.08001 148.045 7.44905 148.802 8.09558C149.524 8.71218 149.996 9.74766 149.996 11.56C149.996 13.3724 149.524 14.4078 148.802 15.0244C148.045 15.671 146.804 16.04 144.876 16.04H137.476Z"
            stroke="white"
            strokeWidth="2"
          />
          <path
            d="M189.574 30H190.574V29V25.12V24.12H189.574H174.054V2.20001V1.20001H173.054H168.734H167.734V2.20001V29V30H168.734H189.574Z"
            stroke="white"
            strokeWidth="2"
          />
          <path
            d="M197.167 30H197.818L198.082 29.4047L200.898 23.04H213.756L216.573 29.4047L216.836 30H217.487H222.367H223.909L223.28 28.5925L211.32 1.79248L211.056 1.20001H210.407H204.327H203.678L203.414 1.79248L191.454 28.5925L190.826 30H192.367H197.167ZM206.52 10.4074L206.527 10.3937L206.532 10.3798L207.327 8.44344L208.122 10.3798L208.128 10.3937L208.134 10.4074L211.146 17.16H203.508L206.52 10.4074Z"
            stroke="white"
            strokeWidth="2"
          />
          <path
            d="M225.607 29V30H226.607H238.807C243.174 30 246.835 28.6528 249.409 26.1174C251.984 23.5802 253.367 19.9548 253.367 15.6C253.367 11.2452 251.984 7.61979 249.409 5.08262C246.835 2.54726 243.174 1.20001 238.807 1.20001H226.607H225.607V2.20001V29ZM231.887 24.12V7.08001H238.807C241.793 7.08001 243.714 7.7195 244.915 8.95642C246.123 10.2013 246.807 12.2629 246.807 15.6C246.807 18.9371 246.123 20.9987 244.915 22.2436C243.714 23.4805 241.793 24.12 238.807 24.12H231.887Z"
            stroke="white"
            strokeWidth="2"
          />
          <path
            d="M272.946 30H273.606L273.866 29.3926L285.306 2.59261L285.9 1.20001H284.386H279.306H278.635L278.381 1.82067L269.943 22.4022L261.43 1.81785L261.175 1.20001H260.506H255.386H253.872L254.466 2.59261L265.906 29.3926L266.166 30H266.826H272.946Z"
            stroke="white"
            strokeWidth="2"
          />
          <path
            d="M291.019 30H291.67L291.933 29.4047L294.75 23.04H307.608L310.424 29.4047L310.688 30H311.339H316.219H317.76L317.132 28.5925L305.172 1.79248L304.908 1.20001H304.259H298.179H297.53L297.266 1.79248L285.306 28.5925L284.677 30H286.219H291.019ZM300.372 10.4074L300.378 10.3937L300.384 10.3798L301.179 8.44344L301.974 10.3798L301.979 10.3937L301.985 10.4074L304.998 17.16H297.36L300.372 10.4074Z"
            stroke="white"
            strokeWidth="2"
          />
        </svg>
      </div>
    </>
  );
};

export default Loading;
